import { Currency } from "@/types/currency";

export const currencies = Object.values(Currency).filter((currency) =>
  [Currency.USD, Currency.EUR].includes(currency)
);

export const currencyItems = currencies.map((currency) => ({
  text: currency,
  value: currency,
}));
