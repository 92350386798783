import { Component, Vue } from "vue-property-decorator";
import { copyTextClipboard } from "@helpers";
import API from "@api";

@Component
export default class ReferralInviteUrl extends Vue {
  private inviteLink = {
    loading: false,
    code: "",
    expireAt: 0,
  };

  private get isSmallButton() {
    return !this.$vuetify.breakpoint.xlOnly && !this.$vuetify.breakpoint.mobile;
  }

  private get iconSize() {
    return this.$vuetify.breakpoint.xlOnly || this.$vuetify.breakpoint.mobile
      ? 24
      : 20;
  }

  private get inviteUrl() {
    if (!this.inviteLink.code) return "";

    const { href } = this.$router.resolve({ name: "sign-up" });

    return `${window.location.origin}${href}?referral=${this.inviteLink.code}`;
  }

  private copyLinkClipboard() {
    copyTextClipboard(this.inviteUrl);

    this.$notify({
      type: "info",
      title: this.$vuetify.lang.t("$vuetify.info.copied"),
    });
  }

  private async fetchTeamInvite(options: { refresh?: boolean } = {}) {
    const { refresh = false } = options;

    if (this.inviteLink.loading) return;

    this.inviteLink.loading = true;

    try {
      const { link, expireAt } = await (!refresh
        ? API.referral.fetchReferralInviteUrl()
        : API.referral.refreshReferralInviteUrl());

      const [code] = link.split("=").reverse();

      this.inviteLink.code = code;
      this.inviteLink.expireAt = expireAt - 100;
      this.inviteLink.loading = false;
    } catch (error) {
      this.inviteLink.loading = false;
    }
  }

  private mounted() {
    this.fetchTeamInvite();
  }
}
