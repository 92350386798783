import { Component, Vue } from "vue-property-decorator";
import API from "@api";
import { Currency } from "@/types/currency";
import { currencyItems } from "@admin/config/currency";
import { formatMoney } from "@helpers";

@Component
export default class ReferralProfit extends Vue {
  private loading = false;

  private profitItems: {
    amount: number;
    currency: Currency;
  }[] = currencyItems.map(({ value: currency }) => ({
    amount: 0,
    currency,
  }));

  private formatMoney({
    value,
    currency,
  }: {
    value: number;
    currency: Currency;
  }) {
    return formatMoney({
      value,
      currency,
    });
  }

  private async fetchReferralProfit() {
    if (this.loading) return;

    this.loading = true;

    try {
      const { profit } = await API.referral.fetchReferralProfit();

      Object.entries(profit).forEach(([key, amount]) => {
        const currency = key as Currency;

        const profitItem = this.profitItems.find(
          (item) => item.currency === currency
        );

        if (!profitItem) return;

        profitItem.amount = amount;
      });
    } finally {
      this.loading = false;
    }
  }

  private mounted() {
    this.fetchReferralProfit();
  }
}
