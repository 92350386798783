import { Vue, Component } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import API from "@api";
import { getSymbolCurrency, formatMoney } from "@helpers";
import { ReferralStatistic } from "@/types/referral";
import { Currency } from "@/types/currency";
import { walletModule, profileModule } from "@store/namespaces";
import { WalletGetters } from "@store/modules/wallet/types";
import { ProfileGetters } from "@store/modules/profile/types";

@Component
export default class ReferralProgramMixin extends Vue {
  @walletModule.Getter("hasWalletCurrency")
  private readonly hasWalletCurrencyGetter!: WalletGetters["hasWalletCurrency"];
  @profileModule.Getter("canInviteReferrals")
  private readonly canInviteReferralsGetter!: ProfileGetters["canInviteReferrals"];

  private referralStatistic: {
    items: Readonly<ReferralStatistic[]>;
    loading: boolean;
  } = {
    items: [],
    loading: false,
  };

  private get tableHead() {
    const pushArrayIf = <T>(rule: boolean, item: T): T[] => {
      return rule ? [item] : [];
    };

    return [
      {
        text: "ID",
        rowSpan: 2,
        value: "referralId",
        align: "start",
        enabled: !this.$vuetify.breakpoint.mobile,
      },
      {
        text: this.$vuetify.breakpoint.mobile ? "" : "Email",
        rowSpan: 2,
        value: "referralEmail",
        align: "start",
      },
      {
        text: this.$vuetify.lang.t("$vuetify.fields.deposit_amounts"),
        align: "center",
        width: "16%",
        value: "referralEnrollAmount",
        children: [
          ...pushArrayIf(this.hasWalletCurrencyGetter(Currency.USD), {
            text: getSymbolCurrency(Currency.USD),
            value: "referralEnrollAmount_usd",
            align: "center",
            currency: Currency.USD,
          }),
          ...pushArrayIf(this.hasWalletCurrencyGetter(Currency.EUR), {
            text: getSymbolCurrency(Currency.EUR),
            value: "referralEnrollAmount_eur",
            align: "center",
            currency: Currency.EUR,
          }),
        ],
      },
      {
        text: this.$vuetify.lang.t("$vuetify.fields.to_be_paid"),
        align: "center",
        width: "16%",
        value: "expectedProfitAmount",
        children: [
          ...pushArrayIf(this.hasWalletCurrencyGetter(Currency.USD), {
            text: getSymbolCurrency(Currency.USD),
            value: "expectedProfitAmount_usd",
            align: "center",
            currency: Currency.USD,
          }),
          ...pushArrayIf(this.hasWalletCurrencyGetter(Currency.EUR), {
            text: getSymbolCurrency(Currency.EUR),
            value: "expectedProfitAmount_eur",
            align: "center",
            currency: Currency.EUR,
          }),
        ],
      },
      {
        text: this.$vuetify.lang.t("$vuetify.fields.paid"),
        colSpan: 2,
        align: "center",
        width: "16%",
        value: "actualProfitAmount",
        children: [
          ...pushArrayIf(this.hasWalletCurrencyGetter(Currency.USD), {
            text: getSymbolCurrency(Currency.USD),
            value: "actualProfitAmount_usd",
            align: "center",
            currency: Currency.USD,
          }),
          ...pushArrayIf(this.hasWalletCurrencyGetter(Currency.EUR), {
            text: getSymbolCurrency(Currency.EUR),
            value: "actualProfitAmount_eur",
            align: "center",
            currency: Currency.EUR,
          }),
        ],
      },
    ].filter(({ enabled = true }) => enabled);
  }

  private get headers() {
    return this.tableHead.reduce<DataTableHeader[]>((acc, item) => {
      if (Array.isArray(item.children)) {
        item.children.forEach(({ value, text, align }) =>
          acc.push({
            value,
            text: `${item.text}(${text})`,
            align: align as DataTableHeader["align"],
          })
        );
      } else if (item.value) {
        acc.push({
          text: item.text,
          value: item.value,
          align: item.align as DataTableHeader["align"],
        });
      }

      return acc;
    }, []);
  }

  private get loading() {
    return this.referralStatistic.loading;
  }

  private get items() {
    return this.referralStatistic.items.map(({ payments, ...item }) => {
      let referralEnrollAmount_usd = "0";
      let expectedProfitAmount_usd = "0";
      let actualProfitAmount_usd = "0";

      let referralEnrollAmount_eur = "0";
      let expectedProfitAmount_eur = "0";
      let actualProfitAmount_eur = "0";

      payments.forEach(
        ({
          currency,
          referralEnrollAmount,
          expectedProfitAmount,
          actualProfitAmount,
        }) => {
          switch (currency) {
            case Currency.EUR:
              referralEnrollAmount_eur = this.formatMoney({
                value: referralEnrollAmount,
                showSymbol: false,
                currency,
              });

              expectedProfitAmount_eur = this.formatMoney({
                value: expectedProfitAmount,
                showSymbol: false,
                currency,
              });

              actualProfitAmount_eur = this.formatMoney({
                value: actualProfitAmount,
                showSymbol: false,
                currency,
              });
              break;

            case Currency.USD:
              referralEnrollAmount_usd = this.formatMoney({
                value: referralEnrollAmount,
                showSymbol: false,
                currency,
              });

              expectedProfitAmount_usd = this.formatMoney({
                value: expectedProfitAmount,
                showSymbol: false,
                currency,
              });

              actualProfitAmount_usd = this.formatMoney({
                value: actualProfitAmount,
                showSymbol: false,
                currency,
              });
              break;

            default:
              break;
          }
        }
      );

      return Object.freeze({
        ...item,
        referralEnrollAmount_usd,
        expectedProfitAmount_usd,
        actualProfitAmount_usd,
        referralEnrollAmount_eur,
        expectedProfitAmount_eur,
        actualProfitAmount_eur,
      });
    });
  }

  private formatMoney(options: {
    value: number;
    currency: Currency;
    showSymbol?: boolean;
  }) {
    return formatMoney(options);
  }

  private async fetchReferralStatistic() {
    if (this.referralStatistic.loading || !this.canInviteReferralsGetter) {
      return;
    }

    this.referralStatistic.loading = true;

    try {
      const items = await API.referral.fetchReferralStatistic();

      this.referralStatistic.items = Object.freeze(items);
      return;
    } finally {
      this.referralStatistic.loading = false;
    }
  }

  protected mountedHook(): void {
    this.fetchReferralStatistic();
  }
}
